<template>
  <div id="terms-page" class="d-flex flex-column">
    <div id="content-container">
      <ul id="page-navbar" class="nav">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="$router.back()"><font-awesome-icon :icon="['fas', 'chevron-left']"/> Terug</a>
        </li>
      </ul>

      <div id="content">
        <h1>Privacyverklaring</h1>
        <p>MNNU, gevestigd aan Heidelberglaan 15 3584 CS Utrecht, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
        <p>
          Contactgegevens:
          <br>https://www.mnnu.nl
          <br>Heidelberglaan 15 3584 CS Utrecht
          <br>088 481 8181
        </p>

        <h2>Persoonsgegevens die wij verwerken</h2>
        <p>
          MNNU verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
          <br>- Voor- en achternaam.
          <br>- Geslacht.
          <br>- Geboortedatum.
          <br>- Geboorteplaats.
          <br>- Adresgegevens.
          <br>- Telefoonnummer.
          <br>- E-mailadres.
          <br>- Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch.
        </p>

        <h2>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
        <p>
          MNNU verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van u:
          <br>- Seksuele leven, mits dit van toepassing is voor de behandeling.
          <br>- Gezondheid, mits zelf verstreken of door de behandelaar ingevoerd met uw toestemming.
          <br>- Gegevens van personen jonger dan 16 jaar Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via <a href="mailto:privacy@mnnu.nl">privacy@mnnu.nl</a>, dan verwijderen wij deze informatie.
          <br>- Burgerservicenummer (BSN)
        </p>

        <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
        <p>
          MNNU verwerkt uw persoonsgegevens voor de volgende doelen:
          <br>- Het afhandelen van uw betaling en behandeling.
          <br>- U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren.
          <br>- U te informeren over wijzigingen van onze diensten en producten.
          <br>- U de mogelijkheid te bieden een account aan te maken.
          <br>- Om goederen en diensten bij u af te leveren.
          <br>- Om onze zorg te blijven verlenen.
          <br>- MNNU verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte en declaraties bij de zorgverzekering.
        </p>

        <h2>Grondslag</h2>
        <p>Als u gebruik wilt maken van onze applicatie bent u verplicht om uw gegevens in te voeren in de applicatie. U bent tevens niet verplicht om deze applicatie te gebruiken en kunt ook bij uw behandelaar uw gegevens achterlaten. De behandelaar zal dan uw gegevens in het EPD zetten. Het gebruik van het EPD bent u echter wel verplicht, omdat het MNNU moet voldoen aan haar wettelijke verplichtingen.</p>
        <p>
          De belangen van de gegevensverwerking voor de betrokkene zijn als volgt:
          <br>Het MNNU zal gegevens moeten verwerken en moeten back-uppen om een degelijke applicatie aan te kunnen bieden aan u als gebruiker. Daarnaast moet het MNNU persoonsgegevens verwerken om kosten te kunnen declareren bij de zorgverzekering en om een goede behandeling aan te kunnen bieden. Dit valt dan ook onder het gerechtvaardigd belang volgens de grondslag 1 (toestemming), 3 (wettelijke verplichting).
        </p>

        <h2>Geautomatiseerde besluitvorming</h2>
        <p>Bij het MNNU en in de applicatie wordt er (vooralsnog) geen gebruik gemaakt van geautomatiseerde besluitvorming, profilering of big data. Bedoelend hiermee is dat er een gebruik gemaakt wordt van computerprogramma's of -systemen om besluiten te nemen, zonder dat daar een mens (bijvoorbeeld een medewerker van MNNU) tussen zit.</p>

        <h2>Hoe lang we persoonsgegevens bewaren</h2>
        <p>MNNU bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorie&euml;n) van persoonsgegevens: 20 jaar volgens de De Wet op de Geneeskundige Behandelingsovereenkomst (WGBO).</p>

        <h2>Delen van persoonsgegevens met derden</h2>
        <p>MNNU verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. MNNU blijft verantwoordelijk voor deze verwerkingen.</p>

        <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
        <p>MNNU gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>

        <h2>Gegevens inzien, aanpassen of verwijderen</h2>
        <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kunt u zelf doen via de persoonlijke instellingen van uw account. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door ons bedrijf en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. Tevens kunt ook via de instellingen van uw account deze gegevens downloaden. Wilt u gebruik maken van uw recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heeft u andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar <a href="mailto:privacy@mnnu.nl">privacy@mnnu.nl</a>.</p>
        <p>Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. MNNU zal zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek reageren. MNNU wil u er tevens op wijzen dat u de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a></p>

        <h2>Hoe wij persoonsgegevens beveiligen</h2>
        <p>MNNU neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <a href="mailto:privacy@mnnu.nl">privacy@mnnu.nl</a>.</p>
      </div>
    </div>

    <auth-footer></auth-footer>
  </div>
</template>

<script>
import AuthFooter from '@/components/layout/AuthFooter'

export default {
  name: 'Privacy',
  components: { AuthFooter }
}
</script>
