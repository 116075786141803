<template>
  <div id="login-page" class="d-flex flex-column">
    <div id="content-container">
      <div class="float-right">
        <button type="button" class="btn btn-lg text-black-50" data-toggle="modal" data-target="#pageHelpModal" aria-label="Help">
          <i class="bi bi-question-circle" style="font-size: 1.5rem;"></i>
        </button>
      </div>

      <div id="content">
        <h2 class="title font-weight-normal">Welkom terug!</h2>

        <form method="POST" action="#" class="d-flex flex-column">

          <div class="flex-fill">
            <div class="form-group">
              <label for="email" class="font-weight-medium">E-mailadres</label>
              <input id="email" type="email" class="form-control" :class="{ 'is-invalid': error }" name="email" required autofocus autocomplete="email" @input="updateEmail">

              <span v-if="error" class="invalid-feedback" role="alert">
                <strong>[[error message]]</strong>
              </span>
            </div>
            <div class="form-group">
              <label for="password" class="font-weight-medium">Wachtwoord</label>
              <input id="password" type="password" class="form-control" :class="{ 'is-invalid': error }" name="password" required autocomplete="current-password">

              <span v-if="error" class="invalid-feedback" role="alert">
                <strong>[[error message]]</strong>
              </span>

              <small class="form-text">
                <a href="#">Wachtwoord vergeten?</a>
              </small>
            </div>
          </div>
          <div class="mt-3">
            <router-link to="/loading" class="btn btn-primary btn-block">Inloggen</router-link>
            <router-link :to="{name: 'register'}" class="btn btn-outline-primary btn-block">Ik heb nog geen account</router-link>
          </div>
        </form>
      </div>
    </div>

    <auth-footer></auth-footer>
  </div>
</template>

<script>
import AuthFooter from '@/components/layout/AuthFooter'
import { mapState } from 'vuex'

export default {
  name: 'Login',
  components: { AuthFooter },
  data () {
    return {
      error: false
    }
  },
  computed: {
    ...mapState([
      'current_user'
    ])
  },
  methods: {
    updateEmail (e) {
      this.$store.commit('setCurrentUserEmail', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
#content-container {
  max-width: 400px !important;
  width: 100%;
}
</style>
