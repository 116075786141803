<template>
  <div class="circle-loader" :class="status">
    <div class="status draw"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  data () {
    return {
      status: String
    }
  },
  created () {
    this.$eventBus.$on('changeLoadingStatus', ($event) => {
      this.status = $event
    })
  }
}
</script>
