<template>
  <div id="register-page" class="d-flex flex-column">
    <div id="content-container">
      <div class="float-right">
        <button type="button" class="btn btn-lg text-black-50" data-toggle="modal" data-target="#pageHelpModal" aria-label="Help">
          <i class="bi bi-question-circle" style="font-size: 1.5rem;"></i>
        </button>
      </div>

      <div id="content">
        <h2 class="title font-weight-normal">Account aanmaken</h2>

        <form method="POST" action="#" class="d-flex flex-column">

          <div class="flex-fill">
            <div class="form-group">
              <label for="email" class="font-weight-medium">E-mailadres</label>
              <input id="email" type="email" class="form-control" :class="{ 'is-invalid': error }" name="email" required autofocus autocomplete="email">

              <span v-if="error" class="invalid-feedback" role="alert">
                <strong>[[error message]]</strong>
              </span>
            </div>
            <div class="form-group">
              <label for="password" class="font-weight-medium">Wachtwoord</label>
              <input id="password" type="password" class="form-control" :class="{ 'is-invalid': error }" name="password" required autocomplete="current-password">

              <span v-if="error" class="invalid-feedback" role="alert">
                <strong>[[error message]]</strong>
              </span>
            </div>
          </div>
          <div class="mt-3">
            <router-link to="/loading" class="btn btn-primary btn-block">Registreren</router-link>
            <router-link :to="{name: 'preAuth'}" class="btn btn-outline-primary btn-block">Annuleren</router-link>
          </div>
          <div class="text-center mt-3" style="font-size: 0.9rem;">
            Door te registreren gaat u akkoord met onze <router-link :to="{name: 'terms'}" target="_blank">Voorwaarden</router-link>.
            Raadpleeg en bekijk hier ons <router-link :to="{name: 'privacy'}" target="_blank">Privacybeleid</router-link>.
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      error: false
    }
  },
  mounted () {
    $('input#password').popover({
      trigger: 'focus',
      placement: 'bottom',
      content: '<ul class="list-unstyled m-0">' +
        '<li><i class="bi bi-x-lg text-danger"></i> 10-64 karakters</li>' +
        '<li>' +
        '<i class="bi bi-x-lg text-danger"></i> Minimaal &eacute;&eacute;n:' +
        '<ul><li>Hoofdletter;</li>' +
        '<li>Kleine letter;</li>' +
        '<li>Nummer;</li>' +
        '<li>Speciaal karakter.</li></ul>' +
        '</li>' +
        '</ul>',
      html: true,
      container: '#content'
    })
  }
}
</script>

<style lang="scss" scoped>
#content-container {
  max-width: 400px !important;
  width: 100%;
}
</style>

<style lang="scss">
// Since this is dynamically inserted, scoped isn't gonna work
#content .popover {
  max-width: 100%;
}
</style>
