<template>
  <div id="footer-links">
      <router-link :to="{name: 'terms'}" class="text-reset" >Voorwaarden</router-link> |
      <router-link :to="{name: 'privacy'}" class="text-reset" >Privacybeleid</router-link>
  </div>
</template>

<script>
export default {
  name: 'AuthFooter'
}
</script>
