<template>
  <div id="pre-login-page" class="d-flex flex-column">
    <div id="content-container">
      <div class="float-right">
        <button type="button" class="btn btn-lg text-black-50" data-toggle="modal" data-target="#pageHelpModal" aria-label="Help">
          <i class="bi bi-question-circle" style="font-size: 1.5rem;"></i>
        </button>
      </div>

      <div id="content">
        <h2 class="title">
          Welkom,
          <br>
          <small>heeft u al een account bij ons?</small>
        </h2>
        <div style="margin: 60px 0;">
          <router-link :to="{name: 'login'}" class="btn btn-primary btn-block btn-md">Ja, ik heb al een account</router-link>
          <router-link :to="{name: 'register'}" class="btn btn-outline-primary btn-block btn-md">Nee, ik heb nog geen account</router-link>
        </div>
      </div>
    </div>

    <auth-footer></auth-footer>
  </div>
</template>

<script>
import AuthFooter from '@/components/layout/AuthFooter'

export default {
  name: 'PreAuth',
  components: { AuthFooter }
}
</script>
