<template>
  <div id="loading-page" class="d-flex flex-column">
    <div id="content-container">
      <div id="content" class="text-center">
        <loading-indicator></loading-indicator>

        <h2>
          Uw sessie wordt voorbereid
          <br>
          <small>Een ogenblik geduld alstublieft</small>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from '@/components/ui/LoadingIndicator'

export default {
  name: 'Login',
  components: { LoadingIndicator },
  mounted () {
    setTimeout(this.demoLoading, 3000)
  },
  methods: {
    demoLoading () {
      this.$eventBus.$emit('changeLoadingStatus', 'success')
      setTimeout(() => this.$router.push({ name: 'contacts' }), 2000)
    }
  }
}
</script>
