<template>
  <div id="terms-page" class="d-flex flex-column">
    <div id="content-container">
      <ul id="page-navbar" class="nav">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="$router.back()"><font-awesome-icon :icon="['fas', 'chevron-left']"/> Terug</a>
        </li>
      </ul>

      <div id="content">
        <h1>Voorwaarden</h1>
        <h4>Geheime authenticatiegegevens gebruik</h4>
        <p>Het MNNU zal gebruik gaan maken van self sovereign identity (SSI), waarbij het hoofddoel is dat u verantwoordelijk blijft over uw eigen data. Dit houdt ook in dat u de verantwoording draagt voor de authenticatiegegevens. Onder authenticatiegegevens valt: wachtwoorden, gebruikersnaam, tweestapsauthenticatie, wachtwoordenkluis en inlogmethoden (de manier waarop u inlogt bijvoorbeeld met een e-mail of SMS).</p>
        <p>Het verantwoord omgaan met uw eigen authenticatiegegevens betekent o.a. het niet delen of openbaar maken van de authenticatiegegevens met andere partijen. Dit geldt niet voor de mantelzorgers die toestemming hebben om uw gegevens in te zien.</p>
        <p>Het MNNU zal alleen een platform aanbieden voor de applicatie die volgens de richtlijnen van de <a href="https://www.nen.nl/nen-7510-1-2017-a1-2020-nl-267179">NEN7510</a> is ingericht. Bij een datalek door eigen toedoen is het MNNU dan ook niet verantwoordelijk.</p>

        <h4>Wachtwoordbeleid</h4>
        <p>Hieronder ziet u een overzicht van de complexiteit waaraan een wachtwoord zal moeten voldoen. Van u als gebruiker wordt verwacht dat u hieraan voldoet. Dit zal automatisch worden afgedwongen bij het aanmaken en-/of wijzingen van het wachtwoord.</p>
        <p><b>Complexiteit van wachtwoorden</b></p>
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="col">Account type</th>
              <th scope="col">Lengte en complexiteit</th>
              <th scope="col">Leeftijd</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pati&euml;nt account MNNU</td>
              <td>
                <p>10 &ndash; 64 karakters.</p>
                <p>
                  Bevat minimaal 1 van elk van het onderstaande:
                  <br>- Hoofdletter;
                  <br>- Kleine letter;
                  <br>- Nummer;
                  <br>- Speciaal karakter.
                </p>
                <p>
                  Gebruik minimaal &eacute;&eacute;n van de onderstaande tweestapauthenticatie methodes:
                  <br>- E-mail;
                  <br>- Token;
                  <br>- SMS.
                </p>
              </td>
              <td>Het wachtwoord mag maximaal 1 jaar oud zijn, waarna u gevraagd wordt deze aan te passen.</td>
            </tr>
          </tbody>
        </table>

        <p><b>Inlogeisen van het account</b></p>
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Account type</th>
              <th>Aantal foutieve inlogpogingen tot uitsluiting account</th>
              <th>Account uitsluiting na foutieve pogingen</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pati&euml;nt account</td>
              <td>4</td>
              <td>15 minuten</td>
            </tr>
          </tbody>
        </table>

        <p>
          <b>Wachtwoord vergeten</b>
          <br>Elke gebruiker heeft de mogelijkheid om zijn wachtwoord te wijzigen via het account menu. Om het wachtwoord opnieuw in te stellen zal er een unieke code worden gegenereerd en zal er middels een e-mail een link worden verstuurd om het wachtwoord opnieuw in te kunnen stellen. Het wachtwoord moet binnen 60 minuten worden aangepast. Gebeurt dit niet, dan zal de unieke code verlopen en moet het bovengenoemde proces opnieuw worden uitgevoerd.
        </p>

        <h4>Aanbevelingen</h4>
        <p>
          <b>Opslag van wachtwoorden</b>
          <br>Het advies is om gebruik te maken van een wachtwoordenkluis. De richtlijnen hiervoor zijn als volgt:
          <br>- Het hoofd wachtwoord zal minimaal 12-64 karakters moeten bevatten, waarbij deze minimaal beschikt over 3 van de 4 volgende eigenschappen: een hoofdletter, kleine letter, nummer en-/of speciaal karakter.
          <br>- Het wachtwoord moet jaarlijks worden gewijzigd.
          <br>- Tweestapsauthenticatie met een extern authenticatie apparaat, zoals een authenticatie app of hardwarematige een sleutel.
          <br>- Er zal een back-up gemaakt moeten worden van wachtwoordenkluis.
          <br>- Als er gebruik gemaakt wordt van een online wachtwoordenkluis, dan gebruikt deze HTTPS.
          <br>- De wachtwoordkluis gebruikt minimaal AES-256 encryptie.
        </p>
        <p>
          <b>Aanmaken van wachtwoorden</b>
          <br>Ten behoeve van het aanmaken van een wachtwoord is het advies om te letten op het volgende:
          <br>- Wachtwoorden mogen niet meer dan 3 herhalende of opeenvolgende tekens bevatten. Dus niet “aaaa” of “1234”
          <br>- Wachtwoorden mogen niet het volgende of combinaties hiervan bevatten: bestaande woorden, toetsenbord patronen (bijv.: qwerty,qaz), namen, plaatsen, merken, gebruikersnamen.
          <br>- Bij het gebruik van tijdelijke wachtoorden moet deze worden gewijzigd na de eerste succesvolle inlogpoging.
        </p>
      </div>
    </div>

    <auth-footer></auth-footer>
  </div>
</template>

<script>
import AuthFooter from '@/components/layout/AuthFooter'

export default {
  name: 'Terms',
  components: { AuthFooter }
}
</script>
